import React, { useEffect, useState } from 'react';
import { Button, Input, HeroTitle, Fieldset } from '@cof/gravity-react';

import { customerForm } from '../../strings';
import { appIdRegex, numberRegex, getAppIdType } from '../../utils/validations';

import './CustomerForm.scss';

const maskInput = (input, setFunction, maxLength, setCheckedFunction, setValidFunction) => {
  if (numberRegex.test(input) && (input.length <= maxLength)) {
    setFunction(input);
    if (input.length === maxLength) {
      setValidFunction(true);
      setCheckedFunction(true);
    }
  }
};

const CustomerForm = ({ pageData, activeLanguage, potomacLoaded, advancePage, showFaqDialog }) => {

  const appIdType = getAppIdType(document.location.search);

  const [srn, setSrn] = useState('');
  const [srnChecked, setSrnChecked] = useState(false);
  const [srnValid, setSrnValid] = useState(false);

  const [social, setSocial] = useState('');
  const [socialChecked, setSocialChecked] = useState(false);
  const [socialValid, setSocialValid] = useState(false);
  const [isSocialVisible, setIsSocialVisible] = useState(false);
  const [zipCode, setZipCode] = useState('');
  const [zipCodeChecked, setZipCodeChecked] = useState(false);
  const [zipCodeValid, setZipCodeValid] = useState(false);

  const [applicationEntryPoint, setApplicationEntryPoint] = useState(pageData.applicationEntryPoint || '');

  const srnError = srnChecked && !srnValid;
  const zipError = zipCodeChecked && !zipCodeValid;
  const socialError = socialChecked && !socialValid;

  const formHasEmpty = srn === '' || social === '' || zipCode === '';
  const buttonDisabled = srnError || (srn.length < appIdRegex[appIdType].minAppIdLen || srn.length > appIdRegex[appIdType].maxAppIdLen) || zipError || zipCode.length !== 5 || socialError || social.length !== 4 || formHasEmpty;

  useEffect(() => {
    if (potomacLoaded) {
      console.log('Sending pageView event for Customer Form');
      window.sp(
        'trackPageView', 
        'v2 - Customer Form',
      );
    }
  }, [potomacLoaded]);

  const maskSrnInput = (value) => {
    if (appIdRegex[appIdType].characterClass.test(value)){
      setSrn(value.toLowerCase().substring(0, appIdRegex[appIdType].maxAppIdLen));
    }
  };
  const checkSrnInput = (value) => {
    setSrnChecked(true);
    setSrnValid(appIdRegex[appIdType].validClass.test(value));
  };

  if (document.location.pathname === '/funding' || document.location.pathname === '/deposit') {
    const searchParams = new URLSearchParams(document.location.search);
    const prefillSRN = searchParams.get('srn');
    if(prefillSRN) {
      maskSrnInput(prefillSRN);
      window.history.replaceState(null, null, '/');
      setApplicationEntryPoint('fromNewEmail');
    }
  }

  return (
    <div className="customer-form">
      <HeroTitle>{customerForm[activeLanguage].AuthenticationFormTitle}</HeroTitle>
      <Fieldset legend={customerForm[activeLanguage].Subtitle}>
        <Input
          label={customerForm[activeLanguage].CustomerNumber}
          helper={customerForm[activeLanguage].Received}
          value={srn}
          id="customer-form-srn"
          autoComplete="off"
          error={srnError && customerForm[activeLanguage][appIdType].CustomerNumberBlank}
          onChange={e => maskSrnInput(e.target ? e.target.value : '')}
          onBlur={e => checkSrnInput(e.target ? e.target.value : '')}
        />
        <Input
          label={customerForm[activeLanguage].Zip}
          helper=""
          value={zipCode}
          id="customer-form-zip"
          autoComplete="off"
          error={zipError && customerForm[activeLanguage].ZipCodeError}
          onChange={e => maskInput(e.target.value, setZipCode, 5, setZipCodeChecked, setZipCodeValid)}
          onBlur={_e => { setZipCodeValid(zipCode.length === 5); setZipCodeChecked(true); }}
        />
        <div className="ssn-toggle">
          <span className="grv-label__header">
            <label className="grv-label grv-label--textfield">{customerForm[activeLanguage].SSN}</label>
            <button
              aria-label="Show Last 4 Digits of SSN"
              className="grv-label__info grv-label__info--button"
              onClick={() => setIsSocialVisible(!isSocialVisible) }
            >
              {isSocialVisible ? customerForm[activeLanguage].SSNHide : customerForm[activeLanguage].SSNShow}
            </button>
          </span>
          <Input
            helper=""
            value={social}
            id="customer-form-social"
            type={isSocialVisible ? 'text' : 'password'}
            autoComplete="off"
            error={socialError && customerForm[activeLanguage].SSNInvalid}
            onChange={e => maskInput(e.target.value, setSocial, 4, setSocialChecked, setSocialValid)}
            onBlur={_e => {setSocialValid(social.length === 4); setSocialChecked(true);}}
            onEnter={_e => {social.length === 4 && !buttonDisabled && advancePage({ srn, social, zipCode, applicationEntryPoint });}}
          />
        </div>
        <div className="button-group">
          <Button
            constantWidth={false}
            type="text"
            onClick={showFaqDialog}
          >
            {customerForm[activeLanguage].FAQ}
          </Button>
          <Button
            constantWidth={false}
            type="progressive"
            disabled={buttonDisabled}
            onClick={_e => { advancePage({ srn, social, zipCode, applicationEntryPoint }); }}
          >
            {customerForm[activeLanguage].MakeDeposit}
          </Button>
        </div>
      </Fieldset>
    </div>
  );
};

export default CustomerForm;
