const appIdRegex = {
  alphanumeric: {
    characterClass: /^[0-9a-zA-Z]*$/,
    validClass: /^[0-9a-zA-Z]{14,16}$/,
    minAppIdLen: 14,
    maxAppIdLen: 16,
  },
  numeric: {
    characterClass: /^[0-9]*$/,
    validClass: /^[0-9]{14,17}$/,
    minAppIdLen: 14,
    maxAppIdLen: 17,
  },
};

const numberRegex = /^[0-9]*$/;

const textRegex = /^[a-zA-Z0-9.'#, ]*$/;

const alphaNumRegex = /^[a-zA-Z0-9]*$/;

const eidRegex = /^[a-zA-Z]{3}[0-9]{3}$/;

const routingRegex = /^[0-9]{0,9}$/;

const getAppIdType = (params) => {
  const paramsSearch = new URLSearchParams(params);
  const paramsAlphanumeric = paramsSearch.get('alphanumeric');

  if (!paramsAlphanumeric) {
    return process.env.REACT_APP_ID_TYPE || 'numeric';
  }

  return paramsAlphanumeric === 'true' ? 'alphanumeric' : 'numeric';
};

const validateAppId = (appIdType, srn, social, zipCode) => {
  return (
    appIdRegex[appIdType].characterClass.test(srn) &&
    appIdRegex[appIdType].validClass.test(srn) &&
    srn.length >= appIdRegex[appIdType].minAppIdLen &&
    srn.length <= appIdRegex[appIdType].maxAppIdLen &&
    numberRegex.test(social) &&
    social.length === 4 &&
    numberRegex.test(zipCode) &&
    zipCode.length === 5
  );
};

export { appIdRegex, numberRegex, textRegex, alphaNumRegex, eidRegex, routingRegex, getAppIdType, validateAppId };
